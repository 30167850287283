import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/tile-rank/virtual-user', { params })
    return response
  },
  async get(params) {
    let response = await api.get('/admin/tile-rank/virtual-user/detail', {
      params,
    })
    return response.data.data || null
  },
  async create(payload) {
    let response = await api.post('/admin/tile-rank/virtual-user', payload)
    return response
  },
  async createMany(payload) {
    let response = await api.post('/admin/tile-rank/virtual-user/create-users', payload)
    return response
  },
  async update(payload) {
    let response = await api.put('/admin/tile-rank/virtual-user', payload)
    return response
  },
  async delete(params) {
    let response = await api.delete('/admin/tile-rank/virtual-user', { params })
    return response
  },
}
